import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import NotificationAlert from 'react-notification-alert';
import { Navigate } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';
import * as yup from 'yup';
import { apiForgotPassword } from 'src/api';
import Button from 'src/components/Button';
import FormGroup from 'src/components/FormFields/FormGroup';
import FormInput from 'src/components/FormFields/Input/FormInput';
import authClasses from '../Auth.module.scss';
import { useIndexData } from './selectorData';

const formSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email format')
    .required('This field is required'),
});

const ForgotPassword = () => {
  const { isLoggedIn } = useIndexData();
  const notificationAlertRef = useRef(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(null);
  const { handleSubmit, control, setError } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: '',
    },
  });
  // eslint-disable-next-line no-unused-vars
  const notify = (type, title) => {
    let options = {
      place: 'tc',
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
        </div>
      ),
      type: type,
      icon: 'ni ni-bell-55',
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const res = await apiForgotPassword({
        email: data.email,
      });
      if (res?.isSuccess) {
        setSuccess(true);
      } else {
        setError('email', {
          type: 'required',
          message: 'Email is not existing',
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError('email', {
        type: 'required',
        message: 'Email is not existing',
      });
    }
  };

  if (isLoggedIn) {
    return <Navigate to="/dashboard" replace />;
  }
  return (
    <>
      <Card className={cn('border-0 mb-0', authClasses.card)}>
        <CardHeader className="bg-transparent p-0 border-0">
          <div className="text-muted text-center">
            <h2 className={authClasses.title}>OMNI</h2>
            <p className={authClasses.subtitle}>Reset Password</p>
            <p className={authClasses.description}>
              Enter your email below and we’ll send you a link to reset your
              password
            </p>
          </div>
        </CardHeader>
        <CardBody className={authClasses.cardBody}>
          {success ? (
            <div className={authClasses.alert}>
              <span>
                Success! If there is an account associated with that email,
                we’ll send a link for resetting the password.
              </span>
            </div>
          ) : (
            <form
              role="form"
              className={authClasses.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className={authClasses.formFields}>
                <FormGroup>
                  <FormInput
                    name="email"
                    placeholder="Username or email"
                    control={control}
                  />
                </FormGroup>
              </div>
              <div className="text-center">
                <Button
                  className={cn(authClasses.button, 'mt-4')}
                  color="info"
                  type="submit"
                  loading={loading}
                >
                  Submit
                </Button>
              </div>
            </form>
          )}
        </CardBody>
      </Card>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
    </>
  );
};

export default ForgotPassword;
