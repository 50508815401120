import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import NotificationAlert from 'react-notification-alert';
import { Link, Navigate } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';
import * as yup from 'yup';
import { apiGenerate2FA, apiVerify2FACode } from 'src/api';
import Button from 'src/components/Button';
import Checkbox from 'src/components/FormFields/Checkbox';
import FormGroup from 'src/components/FormFields/FormGroup';
import Input from 'src/components/FormFields/Input';
import FormInput from 'src/components/FormFields/Input/FormInput';
import FormInputPassword from 'src/components/FormFields/InputPassword/FormInputPassword';
import authClasses from '../Auth.module.scss';
import classes from './Login.module.scss';
import { useActions, useIndexData } from './selectorData';

const formSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email format')
    .required('This field is required'),
  password: yup.string().required('This field is required'),
});

const Login = () => {
  const { login, syncSetLoginData } = useActions();
  const { loginLoading, isLoggedIn } = useIndexData();
  const notificationAlertRef = useRef(null);
  const [generateFALoading, setGenerateFALoading] = useState(false);
  const [verifyCodeLoading, setVerifyCodeLoading] = useState(false);
  const [faInfo, setFAInfo] = useState({});
  const [faCode, setFACode] = useState('');
  const isFAStep = !!faInfo?.isSuccess;
  const notify = (type, title) => {
    let options = {
      place: 'tc',
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
        </div>
      ),
      type: type,
      icon: 'ni ni-bell-55',
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const { handleSubmit, control, setError } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = async (data) => {
    setFACode('');
    login(data, async (res) => {
      if (res?.isSuccess) {
        if (!res?.data?.['2fa_enabled']) {
          try {
            setGenerateFALoading(true);
            const faRes = await apiGenerate2FA(res?.data?.token);
            if (faRes?.isSuccess) {
              setFAInfo({
                ...faRes,
                token: res?.data?.token,
              });
            } else {
              notify('danger', '2FA generate failed. Please try again!');
              setFAInfo({});
            }
            setGenerateFALoading(false);
          } catch (error) {
            notify('danger', '2FA generate failed. Please try again!');
            setFAInfo({});
            setGenerateFALoading(false);
          }
        } else {
          setFAInfo({
            isSuccess: true,
            isEnterCodeManually: true,
            token: res?.data?.token,
          });
        }
      } else {
        setError('password', {
          type: 'required',
          message: 'Credentials don’t match',
        });
      }
    });
  };
  const verifyFACode = async () => {
    try {
      setVerifyCodeLoading(true);
      const res = await apiVerify2FACode(
        {
          code: faCode,
        },
        faInfo.token
      );
      if (res?.isSuccess) {
        syncSetLoginData(res.data);
      } else {
        setFACode('');
        notify('danger', '2FA Code invalid. Please try again!');
      }
      setVerifyCodeLoading(false);
    } catch (error) {
      setFACode('');
      notify('danger', '2FA Code invalid. Please try again!');
      setVerifyCodeLoading(false);
    }
  };

  if (isLoggedIn) {
    return <Navigate to="/dashboard" replace />;
  }
  return (
    <>
      <Card className={cn('border-0 mb-0', authClasses.card)}>
        <CardHeader className="bg-transparent p-0 border-0">
          <div className="text-muted text-center">
            <h2 className={authClasses.title}>OMNI</h2>
            <p className={authClasses.subtitle}>
              {isFAStep ? '2-Factor Authentication' : 'Welcome Back'}
            </p>
            {isFAStep && (
              <div className="mt-3">
                <span className={classes.subTitle1}>
                  Open your Authenticator app and{' '}
                  {faInfo?.isEnterCodeManually
                    ? 'enter the code'
                    : 'scan this QR Code'}
                </span>
              </div>
            )}
          </div>
        </CardHeader>
        <CardBody
          className={cn(authClasses.cardBody, {
            [classes.faCardBody]: isFAStep,
          })}
        >
          {isFAStep ? (
            <div className={classes.faCodeWrapper}>
              {!faInfo?.isEnterCodeManually && (
                <>
                  <img src={faInfo.image} alt="qr-code" />
                  <div className="mt-2">
                    <span>Or manually enter the following code:</span>
                  </div>
                  <p className="mt-4">{faInfo.secret}</p>
                </>
              )}
              <div className={cn('mt-4', classes.faCodeInput)}>
                <Input
                  placeholder="Authenticator 6 digits"
                  value={faCode}
                  onChange={(e) => {
                    if (Number(e.target.value) >= 0 || !e.target.value) {
                      setFACode(e.target.value);
                    }
                  }}
                  maxLength={6}
                  onKeyDown={(e) => {
                    if (
                      e.code === 'Enter' ||
                      e.key === 'Enter' ||
                      e.keyCode === 13 ||
                      e.which === 13
                    ) {
                      verifyFACode();
                    }
                  }}
                />
                <Button
                  color="info"
                  type="button"
                  onClick={verifyFACode}
                  loading={verifyCodeLoading}
                  disabled={faCode.length !== 6}
                >
                  Verify
                </Button>
              </div>
            </div>
          ) : (
            <form
              role="form"
              className={authClasses.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className={authClasses.formFields}>
                <FormGroup>
                  <FormInput
                    name="email"
                    placeholder="Email"
                    control={control}
                  />
                </FormGroup>
                <FormGroup>
                  <FormInputPassword
                    name="password"
                    placeholder="Password"
                    control={control}
                  />
                </FormGroup>
              </div>
              <div
                className={cn(
                  'd-flex justify-content-between mt-2 px-2',
                  authClasses.rememberWrapper
                )}
              >
                <Checkbox id="remember" label="Remember me" />
                <div>
                  <Link to="/forgot-password">Forgot password?</Link>
                </div>
              </div>
              <div className="text-center">
                <Button
                  className={cn(authClasses.button, 'mt-4')}
                  color="info"
                  type="submit"
                  loading={loginLoading || generateFALoading}
                >
                  Log In
                </Button>
              </div>
              <div className={cn('mt-4', authClasses.requestAccess)}>
                <span>
                  <span>Don’t have an account?</span>
                  <Link to="#">Request Access</Link>
                </span>
              </div>
            </form>
          )}
        </CardBody>
      </Card>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
    </>
  );
};

export default Login;
