import cn from 'classnames';
import { forwardRef } from 'react';
import { FormFeedback, Input as ReactstrapInput } from 'reactstrap';
import { ReactComponent as WarningSvg } from 'src/assets/icons/warning-2.svg';
import { INPUT_HIGHLIGHT_ERROR_MESSAGE } from 'src/helper/constants';
import InputGroup from '../InputGroup';
import classes from './Input.module.scss';

const Input = forwardRef(({ className, error, ...props }, ref) => {
  return (
    <>
      <InputGroup className="input-group-merge">
        <ReactstrapInput
          className={cn(classes.wrapper, className)}
          ref={ref}
          invalid={!!error}
          {...props}
        />
        {error && error !== INPUT_HIGHLIGHT_ERROR_MESSAGE && (
          <FormFeedback className={classes.error}>
            <WarningSvg />
            <span>{error}</span>
          </FormFeedback>
        )}
      </InputGroup>
    </>
  );
});

export default Input;
