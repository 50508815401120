import ForgotPassword from 'src/pages/Auth/ForgotPassword';
import Login from 'src/pages/Auth/Login';
import ResetPassword from 'src/pages/Auth/ResetPassword';
import ComponentButtons from 'src/pages/Components/Buttons';
import Dashboard from 'src/pages/Dashboard';
// import Home from 'src/pages/Home';

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   icon: 'ni ni-shop text-primary',
  //   component: Home,
  //   layout: 'public',
  // },
  {
    collapse: true,
    name: 'Components',
    icon: 'ni ni-shop text-primary',
    state: 'componentsCollapse',
    views: [
      {
        path: '/components/buttons',
        name: 'Buttons',
        miniName: 'B',
        component: ComponentButtons,
        layout: 'private',
      },
    ],
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'ni ni-shop text-primary',
    component: Dashboard,
    layout: 'private',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    layout: 'auth',
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: ForgotPassword,
    layout: 'auth',
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: ResetPassword,
    layout: 'auth',
  },
];

export default routes;
