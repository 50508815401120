import cn from 'classnames';
import classes from './Checkbox.module.scss';

const Checkbox = ({ className, label, id, ...props }) => {
  return (
    <div
      className={cn(
        'custom-control custom-control-alternative custom-checkbox',
        classes.wrapper,
        className
      )}
    >
      <input
        className="custom-control-input"
        type="checkbox"
        id={id}
        {...props}
      />
      <label className="custom-control-label" htmlFor={id}>
        <span>{label}</span>
      </label>
    </div>
  );
};

export default Checkbox;
