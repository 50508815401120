import cn from 'classnames';
import { Button as ReactstrapButton, Spinner } from 'reactstrap';
import classes from './Button.module.scss';

const Button = ({ className, children, loading, disabled, ...props }) => {
  return (
    <ReactstrapButton
      className={cn(classes.wrapper, className)}
      disabled={loading || disabled}
      {...props}
    >
      {loading && (
        <span className={classes.loading}>
          <Spinner />
        </span>
      )}
      {children}
    </ReactstrapButton>
  );
};

export default Button;
