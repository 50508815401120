import cn from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';
// core components
import AdminFooter from 'src/components/Footers/AdminFooter.js';
import AdminNavbar from 'src/components/Navbars/AdminNavbar.js';
import Sidebar from 'src/components/Sidebar/Sidebar.js';
import routes from 'src/routes';
import classes from './Main.module.scss';
import { useActions } from './selectorData';

const DISPLAY_NAV_ITEMS = ['Dashboard', 'Components'];

const MainLayout = ({ children }) => {
  const { logOutUser } = useActions();
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);
  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = () => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    setSidenavOpen(!sidenavOpen);
  };
  const getNavbarTheme = () => {
    return location.pathname.indexOf('admin/alternative-dashboard') === -1
      ? 'dark'
      : 'light';
  };

  return (
    <>
      <Sidebar
        routes={routes.filter((obj) => DISPLAY_NAV_ITEMS.includes(obj.name))}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: '/',
          imgSrc: require('src/assets/img/brand/argon-react.png'),
          imgAlt: '...',
        }}
      />
      <div className={cn('main-content', classes.wrapper)} ref={mainContentRef}>
        <AdminNavbar
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          brandText={getBrandText(location.pathname)}
          onLogout={() => {
            logOutUser();
          }}
        />
        <div>{children}</div>
        <AdminFooter />
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
};

export default MainLayout;
